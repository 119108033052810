<template>
    <v-select :class="field.class" :style="field.style"
              :placeholder="field.placeholder" :options="options" :disabled="disabled"
              :clearable="field.required !== true" :filterable="field.filterable === true" :taggable="field.taggable === true"
              v-model="selected"
              v-on:close="$el.querySelector('input[type=search]').blur()"
              v-on:input="onInput"
              v-on:open="fetchData"
              v-on:search="onSearch"/>
</template>

<script>
import * as _ from "debounce";

export default {
    name: "SelectField",
    props: ['field', 'data', 'readonly', 'state'],
    data() {
        return {
            disabled: (this.readonly || this.state && (this.state.readonly || this.state.disabled)),
            options: [],
            selected: null,
        }
    },
    created() {
        if (this.field.options) {
            this.options = this.field.options;
        } else if (this.field.optionsfield) {
            this.options = this.data[this.field.optionsfield];
        } else if (this.data?.[this.field.name]) {
            this.options = [this.data[this.field.name]];
        } else if (this.field.default) {
            this.options = [this.field.default];
        }
        if (this.data?.[this.field.name]) {
            this.selected = this.data[this.field.name];
        } else if (this.field.default) {
            this.selected = this.field.default;
        }
    },
    methods: {
        fetchData(filter) {
            if (this.field.api) {
                this.$http.post(this.field.api + '/dropdown', {
                    'filter': filter,
                    'limit': ('preload' in this.field) ? this.field.preload : 50,
                    ...(this.selected && {'id': this.selected.id}),
                }).then((res) => {
                    this.options = res.data.data;
                }).catch((error) => {
                    console.log("SelectField:fetchData():error:", error);
                });
            }
        },
        onInput(data) {
            if (this.data) {
                this.data[this.field.name] = data;
            }
            this.$emit('input', data);
        },
        onSearch: _.debounce(function (filter) {
            this.fetchData(filter);
        }, 450)
    }
}
</script>

<style scoped>
</style>
